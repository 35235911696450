import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faRss } from '@fortawesome/free-solid-svg-icons'
import { faMastodon } from '@fortawesome/free-brands-svg-icons'

const toggleSideBar = () => {
  document.querySelector(".sidebar").classList.toggle("-translate-x-full");
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const aClass = "block py-2.5 px-4 rounded transition duration-200 hover:bg-tint-brown hover:text-white"

  return (
    <>
      <div className="relative min-h-screen md:flex lg:mx-auto">
        <div className="bg-gray-800 text-gray-100 flex justify-between md:hidden">
          <a href="#" className="block p-4 text-white font-bold">Schlu.org</a>
          <button className="mobile-menu-button p-4 focus:outline-none focus:bg-gray-700" onClick={() => toggleSideBar()}>
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        <aside className="sidebar bg-tint-orange text-blue-100 w-56 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out">

          <a href="/" className="text-white flex items-center space-x-2 px-4">
            <span className="text-2xl font-extrabold">Schlu.org</span>
          </a>

          <nav>
            <a href="/" className={aClass}>
              Home
            </a>
            <a href="/archive" className={aClass}>Archive</a>
            <a href="/about" className={aClass}>
              About
            </a>
          </nav>

          <div className="text-white">
            <p className="font-bold px-4 pt-6 mb-2 text-xl">Find me here too</p>
            <a href="https://mastodon.cloud/@schlu" className={aClass}>
              <div className="flex gap-2 text-gray-100 items-center">
                <FontAwesomeIcon icon={faMastodon} />
                <p>Mastodon</p>
              </div>
            </a>

            <a href="/rss.xml" className={aClass}>
              <div className="flex gap-2 text-gray-100 items-center">
                <FontAwesomeIcon icon={faRss} />
                <p>Subscribe</p>
              </div>
            </a>

          </div>
        </aside>
        <main className="content flex-1 px-10 py-5 max-w-4xl">
          {children}
          <footer
            className="text-xs"
            style={{
              marginTop: `2rem`,
            }}
          >
            © {new Date().getFullYear()} Schlu.org
          </footer>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
